<template>
  <div class="delete-account-container">
    <title-header :title="'注销账号'"/>
    <div class="page-container">
      <div class="tips-box">
        <p class="tips-item">您正在申请将账号<span class="highlight">{{ query.mobile }}</span>注销，</p>
        <p class="tips-item">申请注销操作后，账号信息将被清理且无法恢复，请谨慎操作。</p>
      </div>

      <div class="tips-box">
        <p class="tips-item">CTP停车APP，CTP停车小程序、CTP车位锁小程序、CTP车钱包公众号等CTP平台下所有应用及服务采用统一账户体系，若您注销了本账号，您的以下信息将被从物理存储设备清理且永久无法找回：</p>
      </div>

      <div class="tips-box">
        <p class="tips-item">1、登录账号信息</p>
        <p class="tips-item">2、个人资料</p>
        <p class="tips-item">3、停车记录</p>
        <p class="tips-item">4、停车支付账单</p>
        <p class="tips-item">5、月租、优惠券、折扣卡</p>
        <p class="tips-item">6、用户认证信息</p>
      </div>
    </div>

    <button class="btn confirm-btn" @click="confirmDelete">确认注销</button>

  </div>
</template>

<script>
import TitleHeader from "../../components/TitleHeader";
import {Dialog, Toast} from "mand-mobile";
import axios from "axios";
import apiConfig from "../../assets/config/api.config";

export default {
  name: "deleteAccount",
  components: {
    TitleHeader,
  },
  data() {
    return {
      query: null
    }
  },
  created() {
    if(localStorage.getItem('query')) {
      this.query = JSON.parse(localStorage.getItem('query'));
    }
  },
  methods: {
    doDelete() {
      let params = {
        user_id: this.query.user_id,
        user_token: this.query.user_token,
        reason: localStorage.getItem('reason')
      }
      axios.post(apiConfig.deleteUser, params).then(({data}) => {
        console.log(data)
        if(data.code === 0) {
          Dialog.alert({
            title: '提示',
            content: '注销账号成功',
            confirmText: '确定',
            onConfirm: () => { this.$router.push('/delete-reason?user_id='+this.query.user_id+"&user_token="+this.query.user_token+"&mobile="+this.query.mobile) }
          })
        }else {
          Toast.info(data.msg);
        }
      })
    },
    confirmDelete() {
      Dialog.confirm({
        title: '注销账号',
        content: '您确认要注销吗？一旦提交申请将无法撤销，我们将在15个工作日内受理并注销清理您的账户数据。',
        confirmText: '确认',
        onConfirm: () => this.doDelete(),
      })
    }
  }
}
</script>

<style scoped lang="less">
.tips-box {
  margin-bottom: 0.3rem;
  .tips-item {
    line-height: 0.5rem;
  }
}
.highlight {
  color: #FF5733;
  font-weight: 500;
}
</style>
